import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import SEO from '../components/SEO';
import Link from '../components/Link';
import Image from '../components/Image';
import FilterAndContent from '../components/FilterAndContent';
import { slugify } from '../functions';

const nsBase = 'page';
const ns = `${ nsBase }-insights`;

const InsightsPage = ({ pageContext }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		articles,
		mobileView,
	} = pageContext;

	const insightView = article => {
		const articleImage = article.image;
		const subtitle = article.author?.firstName;

		return (
			<div className={`${ ns }__list-item`} key={article.handle}>
				<div className={`${ ns }__list-item-image`}>
					<Link to={`/${ mobileView ? 'mobile-' : '' }insights/${ article.handle }`}>
						<Image {...articleImage} dataMedia />
					</Link>
				</div>
				<div className={`${ ns }__list-item-content`}>
					<div className={`${ ns }__list-item-eyebrow`}>
						{subtitle && (
							<div className={`${ ns }__list-item-subtitle`}>
								{`By ${ subtitle }`}
							</div>
						)}
						<div className={`${ ns }__list-item-date`}>
							{article.publishedAt}
						</div>
					</div>
					<div className={`${ ns }__list-item-title`}>
						<Link to={`/${ mobileView ? 'mobile-' : '' }insights/${ article.handle }`}>
							{article.title}
						</Link>
					</div>
					<div className={`${ ns }__list-item-footer`}>
						<div className={`${ ns }__list-item-categories`}>
							{`Posted in `}
							{article.tags.map(articleTag => {
								if (articleTag.includes('category::')) {
									return (
										<div className={`${ ns }__list-item-category`} key={articleTag}>
											<Link to={`/${ mobileView ? 'mobile-' : '' }insights/?category=${ slugify(articleTag.split('::')[1]) }`}>
												{articleTag.split('::')[1]}
											</Link>
										</div>
									);
								}

								return null;
							})}
						</div>
						<div className={`${ ns }__list-item-read-more`}>
							<Link to={`/${ mobileView ? 'mobile-' : '' }insights/${ article.handle }`}>
								Read more
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className={rootClassnames}>
			{mobileView && (
				<Helmet>
					<body data-mobile-view />
				</Helmet>
			)}
			<SEO title={'Insights'} />
			<div className={'container-fluid'}>
				<h1 className={`${ ns }__title`}>
					Violux Insights.
				</h1>
				<div className={`${ ns }__subtitle`}>
					Keep up-to-date with the latest health insights.
				</div>
			</div>
			<div className={`${ ns }__articles`}>
				<div className={'container-fluid'}>
					<FilterAndContent
						page={'insights'}
						articles={[...articles]}
						filterTag={'category'}
						articleView={insightView}
						initialVisibleAmount={12}
						nextVisibleAmount={6}
					/>
				</div>
			</div>
		</div>
	);
};

export default InsightsPage;
