import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { slugify } from '../../functions';

import Button from '../Button';

const nsBase = 'component';
const ns = `${ nsBase }-filter`;

const removeFilterDuplicates = filters => {
	return (
		filters.filter((thing, index, self) => {
			return (
				index === self.findIndex(t => {
					return t.slug === thing.slug && t.name === thing.name;
				})
			);
		})
	);
};

const Filter = ({
	page,
	articles,
	filterTag,
	handleFilteredArticles,
}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }__${ page }`]: page,
	});

	const [filterSelected, setFilterSelected] = useState('');

	const handleResetState = () => {
		if (filterSelected) {
			handleFilteredArticles([...articles]);
			setFilterSelected('');
			window.history.replaceState(null, null, `/${ page }`);
		}
	};

	const filterArticles = filterSlug => {
		const filteredArticles = [];

		if (filterSlug !== filterSelected) {
			articles.map(article => {
				let taggedArticle = false;
				article.tags.map(articleTag => {
					if (slugify(articleTag.split('::')[1]) === filterSlug) {
						taggedArticle = true;
					}
					return null;
				});

				if (taggedArticle) {
					filteredArticles.push(article);
				}
				return null;
			});
			handleFilteredArticles([...filteredArticles]);
		} else {
			handleResetState();
		}
	};

	const renderFilters = () => {
		const filters = [];

		articles.map(article => {
			article.tags.map(articleTag => {
				if (articleTag.includes(`${ filterTag }::`)) {
					filters.push({
						slug: slugify(articleTag.split('::')[1]),
						name: articleTag.split('::')[1],
					});
				}
				return null;
			});
			return null;
		});

		const newFilters = removeFilterDuplicates(filters);

		return (
			newFilters.map(filter => {
				const filterSelectedCategoryClass = filterSelected === filter.slug ? 'active' : '';
				return (
					<div data-slug={filter.slug} key={filter.slug}>
						<Button
							className={`btn ${ filterSelectedCategoryClass }`}
							variant={'gray-haze'}
							onClick={() => {
								setFilterSelected(filter.slug);
								filterArticles(filter.slug);
								window.history.replaceState(null, null, `/${ page }/?${ filterTag }=${ filter.slug }`);
							}}
						>
							{filter.name}
						</Button>
					</div>
				);
			})
		);
	};

	useEffect(() => {
		const filterChange = () => {
			const queryString = window.location.search;
			const urlParams = new URLSearchParams(queryString);
			const filter = urlParams.get(filterTag);
			// console.log(filter);
			if (filter) {
				if (filter !== filterSelected) {
					setFilterSelected(filter);
					filterArticles(filter);
				}
			}
		};

		filterChange();

		window.addEventListener('replaceState', () => { filterChange(); });

		return window.addEventListener('replaceState', () => { filterChange(); });
	});

	return (
		<div className={rootClassnames}>
			<div className={'container'}>
				<div className={`${ ns }__container`}>
					<div className={`${ ns }__filters`}>
						<Button
							className={`btn ${ filterSelected === '' ? 'active' : '' }`}
							variant={'gray-haze'}
							onClick={e => { handleResetState(e); }}
						>
							All
						</Button>
						{renderFilters()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Filter;
