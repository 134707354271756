import React, { useState } from 'react';
import classNames from 'classnames';

import Filter from '../Filter';
import ContentFeed from '../ContentFeed';

const nsBase = 'component';
const ns = `${ nsBase }-filter-and-content`;

const FilterAndContent = ({
	page,
	articles,
	filterTag,
	articleView,
	filters,
	initialVisibleAmount,
	nextVisibleAmount,
	showAllItems,

}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	// array of items to render
	const [filteredArticles, setFilteredArticles] = useState([...articles]);
	const [filterTransition, setFilterTransition] = useState(false);

	const handleFilteredArticles = items => {
		setFilterTransition(true);
		setTimeout(() => {
			setFilteredArticles(items);
			setFilterTransition(false);
		}, 750);
	};

	const filterTransitionClass = filterTransition ? 'filter-transition' : '';

	return (
		<div className={rootClassnames}>
			<Filter
				page={page}
				articles={[...articles]}
				filterTag={filterTag}
				handleFilteredArticles={handleFilteredArticles}
				filters={filters}
			/>
			<div className={`${ ns }__items ${ filterTransitionClass }`}>
				<ContentFeed
					page={page}
					items={filteredArticles}
					itemView={articleView}
					initialVisibleAmount={initialVisibleAmount}
					nextVisibleAmount={nextVisibleAmount}
					showAllItems={showAllItems}
				/>
			</div>
		</div>
	);
};

export default FilterAndContent;
